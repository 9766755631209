"use strict";
exports.__esModule = true;
exports.photosSchema = exports.photoSchema = void 0;
var zod_1 = require("zod");
/**
 * The schema contains only useful properties of the photo.
 * Other properties are not used in the application.
 */
exports.photoSchema = zod_1.z.object({
    id: zod_1.z.string(),
    description: zod_1.z.string().nullable(),
    // NOTE: needed to link back to the photo at Unsplash
    links: zod_1.z.object({
        html: zod_1.z.string()
    }),
    /**
     * @see https://unsplash.com/documentation#example-image-use
     */
    urls: zod_1.z.object({
        regular: zod_1.z.string()
    }),
    // NOTE: needed for photo attribution
    user: zod_1.z.object({
        name: zod_1.z.string(),
        links: zod_1.z.object({
            html: zod_1.z.string()
        })
    })
});
exports.photosSchema = zod_1.z.array(exports.photoSchema);
